<template>
    <div>
        <TransferenciaProceso
            :monedasimbolo="config.monedasimbolo"
            :userid="config.userid"
            :almacenid="config.almacenid"
        />
    </div>
</template>
    
<script>
    import TransferenciaProceso from './Modulos/TransferenciaProceso.vue'
    
    export default {
        
        props: [
            'usuario',
            'config'
        ],

        components: {
            TransferenciaProceso
        }

    }
</script>
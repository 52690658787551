<template>
    <div v-if="$can('desplazamiento.menu.consultar.traspaso')">
        <a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Traspasos</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus comprobantes de traspasos</span>
			</a-divider>

            <a-col flex="1 1 100px">
                <a-button 
                    block 
                    type="primary" 
                    @click="openMenu('transpaso')" 
                    v-if="$can('desplazamiento.menu.nuevo.traspaso')"
                    >
                    <a-icon type="plus" /> Nuevo Traspaso
                </a-button>
            </a-col>
            
            <a-col flex="1 1 100px">
                <a-button block type="dashed" @click="openModalConsultaOpciones()"><a-icon type="search" /> Consultar Opciones</a-button>
            </a-col>

            <a-col flex="1 1 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_inicio)" 
                    @change="onChangeFechaInicio" 
                    :locale="locale" 
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 10 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_final)" 
                    @change="onChangeFechaFinal" 
                    :locale="locale" 
                    style="width: 100%"
                />
			</a-col>

            <a-col flex="1 1 350px">
                <a-input-search placeholder="Buscar Codigo" @search="onSearchTranspaso">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

		<br>
            
        <a-table 
                :columns="columns" 
                :data-source="transferencias" 
                :pagination="paginate"
                :loading="loading"
                :ellipsis="true"
                size="small"
                :rowKey="record => record.transferencia_id"
                :scroll="{ x: 980 }"
                @change="handlePagination"
                >

                <template slot="sucursalOrigen" slot-scope="item">
                    {{ item.almacen_origen.nombre }}
                </template>

                <template slot="sucursalDestino" slot-scope="item">
                    {{ item.almacen_destino.nombre }}
                </template>

                <template slot="usuario" slot-scope="item">
                    {{ item.usuario.usuario }}
                </template>

                <template slot="fechaTraspaso" slot-scope="item">
                    <span>{{ utils.formatOnlyDate(item.fechaRegistro) }}</span><br>
                    <span>{{ item.horaRegistro }}</span>
                </template>

                <template slot="estado" slot-scope="item">
                    <a-tag color="green" v-if="item.estado === 'APROBADO'">
                        <span>APROBADO</span>
                    </a-tag>
                    <a-tag color="red" v-if="item.estado === 'ANULADO'">
                        <span>ANULADO</span>
                    </a-tag>
                    <a-tag color="blue" v-if="item.estado === 'EN ESPERA'">
                        <span>EN ESPERA</span>
                    </a-tag>
                </template>

                <template slot="fechaAprobacion" slot-scope="item">
                    <span v-if="item.fechaTransaccion">
                        <span>{{ utils.formatOnlyDate(item.fechaTransaccion) }}</span><br>
                        <span>{{ item.horaTransaccion }}</span>
                    </span> 
                    <span v-else>En espera</span>
                </template>

                <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                    <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                    <a-row>
                        <a-col :span="6">
                            <a-list size="small" bordered><a-list-item>ID:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>USUARIO SOLICITANTE DE TRASPASO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>SUCURSAL ORIGEN:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>FECHA DE SOLICITUD:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>USUARIO APROBADOR DE TRASPASO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>SUCURSAL DESTINO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>FECHA DE APROBACION:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>OBSERVACION:</a-list-item></a-list>
                        </a-col>
                        <a-col :span="18">
                            <a-list size="small" bordered><a-list-item>{{ item.transferencia_id }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.codigo }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.usuarioOrigen.usuario }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.almacen_origen.nombre }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ utils.formatOnlyDate(item.fechaRegistro) }} {{ item.horaRegistro }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ (item.usuarioDestino) ? item.usuarioDestino.usuario : 'EN ESPERA' }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ (item.almacen_destino) ? item.almacen_destino.nombre : 'EN ESPERA' }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>
                                <span v-if="item.fechaTransaccion">
                                    {{ utils.formatOnlyDate(item.fechaTransaccion) }} {{ item.horaTransaccion }}
                                </span>
                                <span v-else>
                                    EN ESPERA
                                </span>
                            </a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ (item.observacion) ? item.observacion : 'SIN OBSERVACION' }}</a-list-item></a-list>
                        </a-col>
                    </a-row>
                </span>

                <template slot="action" slot-scope="item">
                    <a-dropdown>
                        <a-menu slot="overlay">

                            <a-menu-item :key="buttonKeyVisualizarComprobante">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Visualizar Comprobante {{ item.codigo }}</span>
                                    </template>
                                    <a href="#" @click.prevent="abrirModalDetalleTraspaso(item)"><a-icon type="eye" :style="{ fontSize: '18px', color: '#006ccc' }"/> &nbsp;<strong>Visualizar Comprobante</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyVerReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Reporte PDF {{ item.codigo }}</span>
                                    </template>
                                    <a href="#" @click.prevent="reporteCarta(item.transferencia_id)"><a-icon type="file-pdf" :style="{ fontSize: '18px', color: '#fa1d03' }"/> &nbsp;<strong>Comprobante PDF</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyImprimirReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Reporte Térmico {{ item.codigo }}</span>
                                    </template>
                                    <a href="#" @click.prevent="imprimirTermico(item.transferencia_id)"><a-icon type="file-text" :style="{ fontSize: '18px', color: '#02c7c1' }"/> &nbsp;<strong>Comprobante Térmico</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <template v-if="item.estado == 'EN ESPERA'">
                                <a-menu-item @click.once="ConfirmarTransferencia(item)" :key="buttonKeyConfirmarTraspaso" v-if="$can('desplazamiento.confirmar.traspaso')">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                            <span>{{ item.codigo }}</span>
                                        </template>
                                        <a-icon type="form" :style="{ fontSize: '18px', color: '#006ccc' }"/> <strong>Confirmar Traspaso</strong>
                                    </a-tooltip>
                                </a-menu-item>

                                <a-menu-item @click.once="AnularTransferencia(item)" :key="buttonKeyAnularTraspaso" v-if="$can('desplazamiento.anular.traspaso')">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                            <span>{{ item.codigo }}</span>
                                        </template>
                                        <a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/><strong style="color:#cc2400; font-weight:700;">Anular Traspaso</strong>
                                    </a-tooltip>
                                </a-menu-item>
                            </template>
                        </a-menu>
                        <a-button> Acciones <a-icon type="down" /> </a-button>
                    </a-dropdown>
                </template>
        </a-table>

        <a-modal
            v-model="modalDetalleTraspaso"
            title="Detalle Traspaso"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="780"
            :zIndex="1050"
            >

            <div class="mb-12">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="invoice-title">
                            <h3>CODIGO DE TRASPASO</h3><h3 class="pull-right"><strong># {{ transferencia.codigo }}</strong></h3>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-xs-6">
                                <address>
                                    <strong>Datos de Traspaso: </strong><br>
                                    Sucursal Origen : {{ transferencia.almacenOrigen }}<br>
                                    Sucursal Destino : {{ transferencia.almacenDestino }}<br>
                                    Observacion : {{ transferencia.observacion }}<br>
                                    <span v-if="transferencia.estado == 'APROBADO'" style="color:green; font-weight:700;">Estado : TRASPASO {{ transferencia.estado }}</span>
                                    <span v-if="transferencia.estado == 'ANULADO'" style="color:red; font-weight:700;">Estado : TRASPASO {{ transferencia.estado }}</span>
                                </address>
                            </div>
                            <div class="col-xs-6 text-right">
                                <address>
                                    <strong>Datos Solicitante:</strong><br>
                                    Usuario Solicitante: {{ transferencia.usuarioOrigen }}<br>
                                    Fecha: {{ utils.formatOnlyDate(transferencia.fechaRegistro) }}<br>
                                    Hora: {{ transferencia.horaRegistro }}
                                </address>
                                <address>
                                    <strong>Datos Aprobador:</strong><br>
                                    Usuario Aprobador: {{ (transferencia.usuarioDestino) ? transferencia.usuarioDestino : 'en espera' }}<br>
                                    Fecha: {{ (transferencia.fechaTransaccion) ? utils.formatOnlyDate(transferencia.fechaTransaccion) : 'en espera' }}<br>
                                    Hora: {{ (transferencia.horaTransaccion) ? transferencia.horaTransaccion : 'en espera' }}
                                </address>
                            </div>
                        </div>
                    </div>
                </div>    
                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title"><strong>Detalle Registrado</strong></h3>
                            </div>
                            <div class="panel-body">
                                <div class="box-body table-responsive no-padding">
                                    <table class="table table-condensed">
                                        <thead>
                                            <tr>
                                                <th><strong>ITEM</strong></th>
                                                <th class="text-center"><strong>ARTICULO</strong></th>
                                                <th class="text-center"><strong>CANT</strong></th>
                                                <th class="text-center"><strong>P.COMPRA</strong></th>
                                                <th class="text-right"><strong>SUBTOTAL</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in transferencia.detalletransferencia" :key="index">
                                                <td>{{ index + 1}}</td>
                                                <td>{{ item.codigo }} {{ item.nombre }} {{ item.medida }}</td>
                                                <td class="text-center">{{ item.cantidad }}</td>
                                                <td class="text-center">{{ item.preciocompra }}</td>
                                                <td class="text-right">{{ item.subtotal }}</td>
                                            </tr>
                                        </tbody> 
                                        <tfoot>       
                                            <tr>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line text-center"><strong>Total</strong></td>
                                                <td class="thick-line text-right" style="font-size:18px; font-weight:700;">{{ transferencia.montoTotal }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalDetalleTraspaso = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <!-- MODAL CONSULTAS OPCIONES -->
        <a-modal
            v-model="modalConsultaOpciones"
            title="Consultar Traspasos"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row type="flex">
                    <a-col flex="1 1 200px" :span="24" v-if="$can('desplazamiento.consultar.traspaso.por.sucursal.de.origen')">
                        <label for="almacen" class="control-label">Sucursal Origen:</label>
                        <a-select size="large" :value="consulta.almacen_origenID" @change="onChageAlmacenOrigen"  style="width: 100%">
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24" v-if="$can('desplazamiento.consultar.traspaso.por.sucursal.de.destino')">
                        <label for="almacen" class="control-label">Sucursal Destino:</label>
                        <a-select size="large" :value="consulta.almacen_destinoID" @change="onChageAlmacenDestino"  style="width: 100%">
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24" v-if="$can('desplazamiento.consultar.traspaso.por.usuario.solicitante')">
                        <label for="proveedor" class="control-label">Usuario Solicitante Traspaso:</label>
                        <a-select size="large" :value="consulta.usuarioOrigenID" @change="onChageUsuarioOrigen"  style="width: 100%">
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario_id"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24" v-if="$can('desplazamiento.consultar.traspaso.por.usuario.aprobador')">
                        <label for="proveedor" class="control-label">Usuario Aprobador Traspaso:</label>
                        <a-select size="large" :value="consulta.usuarioDestinoID" @change="onChageUsuarioDestino"  style="width: 100%">
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario_id"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label class="control-label">Accion:</label>
                        <a-button size="large" type="primary" block @click="obtenerListadoActual">
                            <a-icon type="reload" /> Reestablecer Opciones Busqueda
                        </a-button>
                    </a-col>
                </a-row>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalConsultaOpciones = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'transferencia_id', 
            title: 'Codigo', 
            dataIndex: 'codigo',
            key: 'codigo',
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '15%'
        },
        { 
            id: 'transferencia_id',
            title: 'Sucursal Origen', 
            dataIndex: '',
            key: 'sucursalOrigen',
            scopedSlots: { customRender: 'sucursalOrigen' },
            width: '15%',
            ellipsis: true
        },
        { 
            id: 'transferencia_id', 
            title: 'Sucursal Destino', 
            dataIndex: '',
            key: 'sucursalDestino',
            scopedSlots: { customRender: 'sucursalDestino' }, 
            width: '15%',
            ellipsis: true
        },
        { 
            id: 'transferencia_id',
            title: 'Monto Total', 
            dataIndex: 'montoTotal',
            key: 'montoTotal',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            ellipsis: true
        },
        { 
            id: 'transferencia_id',
            title: 'F.Traspaso', 
            dataIndex: '',
            key: 'fechaTraspaso',
            width: '10%',
            scopedSlots: { customRender: 'fechaTraspaso' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            ellipsis: true
        },
        { 
            id: 'transferencia_id',
            title: 'Estado', 
            dataIndex: '',
            key: 'estado',
            width: '10%',
            scopedSlots: { customRender: 'estado' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            ellipsis: true
        },
        { 
            id: 'transferencia_id',
            title: 'F.Aprobacion', 
            dataIndex: '',
            key: 'fechaTransaccion',
            width: '10%',
            scopedSlots: { customRender: 'fechaAprobacion' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            ellipsis: true
        },
        { 
            id: 'transferencia_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import Swal from 'sweetalert2'
    import router from '@/config/router'
    import { mapGetters, mapActions } from 'vuex'

    export default {

        props: [
            'monedasimbolo', 
            'userid',
            'almacenid'
        ],

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                columns,
                utils,
                consulta: {
                    almacen_origenID: 'Seleccionar',
					almacen_destinoID: 'Seleccionar',
					usuarioOrigenID: 'Seleccionar',
                    usuarioDestinoID: 'Seleccionar',
                    fecha_inicio: '',
                    fecha_final: '',
                    keyword: '',
                    nombreAlmacenOrigen: '',
                    nombreAlmacenDestino: ''
                },
                search: {
                    almacenID: '',
                    keyword: ''
                },
                lista_almacenes: [],
				lista_usuarios: [],
                lista_productos: [],
                buttonKey: 1,
                showModal: false,
                url: '',
                loading: false,
				spinnerloading: false,
                estadoTipoSalida: 0,
                listadosalida_ajuste: [],
                listadosalida: [],
				disabledButton: false,
				disabledButtonUsuario: false,
				lista_permisos: [],
                buttonKeyImprimirReporte: 10,
                buttonKeyVerReporte: 20,
                buttonKeyConfirmarTraspaso: 30,
                buttonKeyAnularTraspaso: 40,
                buttonKeyVisualizarComprobante: 50,
                transferencia: {
                    codigo: '',
                    almacenOrigen: '',
                    almacenDestino: '',
                    observacion: '',
                    estado: '',
                    usuarioOrigen: '',
                    usuarioDestino: '',
                    fechaRegistro: '',
                    horaRegistro: '',
                    fechaTransaccion: '',
                    horaTransaccion: '',
                    montoTotal: '',
                    detalletransferencia: []
                },
                modalDetalleTraspaso: false,
                modalConsultaOpciones: false,
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'transferencia_id',
                    order: 'descend'
                }
            }
        }, 

        created () {
            this.getAlmacenes()
			this.getUsuarioListar()
            this.paginarResultados()
        },

        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios']),
            ...mapGetters('transferencia', ['transferencias'])
        },

        methods: {
            ...mapActions('almacen', ['getAlmacenListado']),
			...mapActions('usuario', ['getUsuarioListar']),
            ...mapActions('transferencia', ['getTransferenciaPaginate', 'confirmar', 'anular']),

            openMenu (name) {
				router.push({ name: name })
			},

            openModalConsultaOpciones () {
                this.modalConsultaOpciones = true
            },

            getAlmacenes () {
                this.getAlmacenListado()
				.then(response => {
					this.consulta.almacen_origenID = this.user.almacenID.toString()
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
				this.consulta.fecha_final = dateString
                this.consulta.keyword = ''
                this.paginarResultados()
			},

            onSearchTranspaso (value) {
                this.consulta.keyword = value
                this.consulta.fecha_inicio = ''
                this.consulta.fecha_final = ''
                this.paginarResultados()
            },

            onChageAlmacenOrigen (value) {
                this.consulta.almacen_origenID = value
                this.paginarResultados()
            },

			onChageAlmacenDestino (value) {
                if (parseInt(this.consulta.almacen_origenID) === parseInt(value)) {
                    utils.openNotificationWithIcon('warning', 'Mensaje : ', 'Debe elegir un Sucursal Destino diferente de Sucursal Origen...', 'topRight')
				} else {
                    this.consulta.almacen_destinoID = value
                    this.paginarResultados()
                }
            },
			
			onChageUsuarioOrigen (value) {
                this.consulta.usuarioOrigenID = value
                this.paginarResultados()
            },

            onChageUsuarioDestino (value) {
                if (parseInt(this.consulta.usuarioOrigenID) === parseInt(value)) {
                    utils.openNotificationWithIcon('warning', 'Mensaje : ', 'Debe elegir un Usuario diferente de Usuario Solicitante...', 'topRight')
				} else {
                    this.consulta.usuarioDestinoID = value
                    this.paginarResultados()
                }
            },

            obtenerListadoActual (value) {
                this.paginate = {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'transferencia_id',
                    order: 'descend'
                }

				this.consulta.almacen_destinoID = 'Seleccionar'
                this.consulta.usuarioOrigenID = ''
                this.consulta.usuarioDestinoID = ''
                this.consulta.fecha_inicio = ''
                this.consulta.fecha_final = ''
                this.consulta.keyword = ''
                this.consulta.nombreAlmacenDestino = ''

                this.paginarResultados()
            },

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'transferencia_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'transferencia_id',
                    order: 'descend'
                }
            },

            paginarResultados () {
				const consultaAlmacen = (this.consulta.almacen_origenID === 'Seleccionar') ? this.user.almacenID : this.consulta.almacen_origenID
				this.consulta.almacen_origenID = (this.$can('desplazamiento.consultar.traspaso.por.sucursal.de.origen')) ? consultaAlmacen : this.user.almacenID

				const consultaUsuario = (this.consulta.usuarioID === 'Seleccionar') ? this.user.usuario_id : this.consulta.usuarioID
				this.consulta.usuarioID = (this.$can('desplazamiento.consultar.traspaso.por.usuario.solicitante')) ? consultaUsuario : this.user.usuario_id

                this.getTransferenciaPaginate({ ...this.paginate, ...this.consulta, ...this.user })
                .then(response => {
                    this.changePaginate(response.data.meta)
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            abrirModalDetalleTraspaso (item) {
                this.transferencia = []

                setTimeout(function () {
                    this.transferencia.codigo = item.codigo
                    this.transferencia.almacenOrigen = item.almacen_origen.nombre
                    this.transferencia.almacenDestino = item.almacen_destino.nombre
                    this.transferencia.observacion = item.observacion
                    this.transferencia.estado = item.estado
                    this.transferencia.usuarioOrigen = item.usuarioOrigen.usuario
                    this.transferencia.usuarioDestino = (item.usuarioDestino) ? item.usuarioDestino.usuario : ''
                    this.transferencia.fechaRegistro = item.fechaRegistro
                    this.transferencia.horaRegistro = item.horaRegistro
                    this.transferencia.fechaTransaccion = item.fechaTransaccion
                    this.transferencia.horaTransaccion = item.horaTransaccion
                    this.transferencia.montoTotal = item.montoTotal
                    this.transferencia.detalletransferencia = item.detalletransferencia

                    this.modalDetalleTraspaso = true
                }.bind(this), 300)
            },

            ConfirmarTransferencia (item) {
                Swal.fire({
                    title: 'Confirmar Traspaso',
                    html: '<h3 style="color:#139e6a;">Estas seguro de confirmar el Traspaso ? </h3><p style="color:#05a024;font-size:15px;">Tome en cuenta que al confirmar el traspaso las cantidades de inventario serán actualizados... </p><hr><table width="100%" border="1">' +
                            '<tr>' +
                                '<td style="font-size:13px;">CODIGO DE TRASPASO: </td>' +
                                '<td style="font-size:13px; color:#0091EA;">' + item.codigo + '</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td style="font-size:13px;">MONTO TOTAL: </td>' +
                                '<td colspan="3" style="font-size:13px; color:#0091EA;">' + item.montoTotal + '</td>' +
                            '</tr>' +
                        '</table> <hr>' +
                        '<table width="100%" border="1">' +
                            '<tr>' +
                                '<td style="font-size:13px;">SUCURSAL ORIGEN:</td>' +
                                '<td style="font-size:13px;color:#0091EA;">' + item.almacen_origen.nombre + '</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td style="font-size:13px;">SUCURSAL DESTINO:</td>' +
                                '<td style="font-size:13px;color:#0091EA;">' + item.almacen_destino.nombre + '</td>' +
                            '</tr>' +
                        '</table>',
                    
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        this.confirmar(item.transferencia_id)
                        .then(response => {
                            if (response.status === 201) {
                                this.paginarResultados()
                                utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                                this.spinnerloading = false

                                const traspasoID = parseInt(response.data.transferenciaID)
                                if (traspasoID) {
                                    if (response.data.imprimirTranferenciaTermica === 'ACTIVADO') {
                                        utils.openRecibo(`/reporte/traspaso/termica/${traspasoID}`)
                                    } else {
                                        utils.openRecibo(`/reporte/traspaso/reporte/carta/${traspasoID}`)
                                    }
                                }
                            }
                        }).catch(error => {
                            this.loading = false
                            utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyConfirmarTraspaso++
            },

            AnularTransferencia (item) {
                Swal.fire({
                    title: 'Desea Anular el Traspaso?',
                    text: 'Tome en cuenta que al anular el Traspaso no afectará al inventario...',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        this.anular(item.transferencia_id)
                        .then(response => {
                            if (response.status === 201) {
                                this.paginarResultados()
                                utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                                this.spinnerloading = false

                                const traspasoID = parseInt(response.data.transferenciaID)
                                if (traspasoID) {
                                    if (response.data.imprimirTranferenciaTermica === 'ACTIVADO') {
                                        utils.openRecibo(`/reporte/traspaso/termica/${traspasoID}`)
                                    } else {
                                        utils.openRecibo(`/reporte/traspaso/reporte/carta/${traspasoID}`)
                                    }
                                }
                            }
                        }).catch(error => {
                            this.loading = false
                            utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyAnularTraspaso++
            },

            imprimirTermico (traspasoID) {
                utils.openRecibo(`/reporte/traspaso/termica/${traspasoID}`)
            },

            reporteCarta (traspasoID) {
                utils.openRecibo(`/reporte/traspaso/reporte/carta/${traspasoID}`)
            }
        }
    }
</script>

<style>
    .btn-opciones-green {
        background-color:#54d406;
        color:#fff;
    }

    .tabla-default-primary{
        background-color:#e1e1e1;
        border-top: 3px solid rgb(32, 80, 129);
    }
</style>